import RecordRTC, { MediaStreamRecorder } from "recordrtc";
import i18n from "@/core/plugins/i18n";
import { getInputDevice } from "@/views/recorder/lib/devices";

interface StartOptions {
  onError?: (errorMessage: string) => void;
}
class VideoRecorder {
  stream: MediaStream | undefined;
  recorder: RecordRTC | undefined;
  muted = false;
  isRecording: boolean;
  isPaused: boolean;

  constructor() {
    this.isRecording = false;
    this.isPaused = false;
  }

  async start(options?: StartOptions) {
    if (this.isRecording || this.isPaused)
      throw new Error("Can't start when already recording or paused.");

    // Check for camera and microphone permissions
    try {
      await this.checkCameraAndMicrophonePermissions();
    } catch (error) {
      console.error("Error checking camera and microphone permissions:", error);
      if (options?.onError)
        // Pass translated message for snackbar
        options.onError(i18n.t("recording.noDevicePermission").toString());

      throw error;
    }

    // Start recording with the obtained stream
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: getInputDevice("audio") },
        video: { deviceId: getInputDevice("video") },
      });
      this.recorder = new RecordRTC(this.stream, {
        recorderType: MediaStreamRecorder,
        mimeType: "video/mp4",
        type: "video",
      });
      this.recorder.startRecording();
      this.isRecording = true;
      return this.stream;
    } catch (error) {
      console.error("Error starting recording:", error);
      return undefined;
    }
  }

  async checkCameraAndMicrophonePermissions(): Promise<void> {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    } catch (error) {
      throw new Error(
        "Camera and microphone permissions are required for recording.",
      );
    }
  }

  pause() {
    this.isPaused = true;
    this.recorder?.pauseRecording();
  }

  resume() {
    this.isPaused = false;
    this.recorder?.resumeRecording();
  }

  async stop(cb: (blob: Blob | undefined) => void) {
    this.recorder?.stopRecording(() => {
      this.stream?.getTracks().forEach(t => t.stop());

      this.isRecording = false;
      this.isPaused = false;

      const initBlob = this.recorder?.getBlob();

      this.recorder!.destroy();
      this.stream = undefined;
      this.recorder = undefined;

      if (!initBlob) {
        cb(undefined);
        return;
      }
      cb(initBlob);
    });
  }

  async reset() {
    this.recorder?.reset();
  }

  toggleMuted() {
    if (this.muted) {
      this.muted = false;
      this.stream?.getAudioTracks().forEach(x => (x.enabled = true));
    } else {
      this.stream?.getAudioTracks().forEach(x => (x.enabled = false));
      this.muted = true;
    }
  }

  async save(fileName: string) {
    this.recorder?.save(fileName);
  }
}

export default VideoRecorder;

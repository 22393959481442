




























































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Recorder from "@/core/utils/videoRecorder";
import DeviceSelect from "@/views/recorder/components/DeviceSelect.vue";

@Component({ components: { DeviceSelect } })
export default class VideoRecorder extends Vue {
  @Prop({ default: () => false }) isTimed!: boolean;
  @Prop({ default: () => 0 }) expectedLen!: number;
  @Prop({ default: () => 0 }) maxLen!: number;
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => true }) canShare!: boolean;
  @Prop({ default: () => true }) canAskForFeedback!: boolean;
  @Prop({ default: () => false }) defaultShared!: boolean;

  recorder: Recorder = new Recorder();
  video: HTMLVideoElement | undefined;
  blob: Blob | undefined;
  isTooBig = false;
  isRecording = false;
  isPaused = false;
  isActive = false;
  flipped = false;

  // time controls
  elapsedTime = 0;
  interval: any = null;
  timeout: any = null;

  startTimeout() {
    this.timeout = setTimeout(
      this.stop,
      (this.maxLen - this.elapsedTime) * 1000,
    );
  }
  startTimer() {
    this.interval = setInterval(() => {
      this.elapsedTime += 1;
    }, 1 * 1000);
  }

  stopTimeout() {
    clearTimeout(this.timeout);
  }
  stopTimer() {
    clearInterval(this.interval);
  }

  async start() {
    if (!this.video) return;

    const stream = await this.recorder.start();
    if (!stream) return;
    this.video.removeAttribute("src");
    this.video.srcObject = stream;
    this.video.play();
    this.video.muted = true;
    this.video.controls = false;
    this.isRecording = true;
    this.isActive = true;

    if (this.isTimed) this.startTimeout();
    this.startTimer();
  }

  pause() {
    this.recorder.pause();
    this.isPaused = true;
    this.stopTimer();
    if (this.isTimed) this.stopTimeout();
  }

  resume() {
    this.recorder.resume();
    this.isPaused = false;
    this.startTimer();
    if (this.isTimed) this.startTimeout();
  }

  stop() {
    this.recorder.stop(blob => {
      if (!this.video || !blob) return;
      this.blob = blob;
      this.video.muted = false;
      this.video.srcObject = null;
      this.video.controls = true;
      this.video.src = URL.createObjectURL(blob);
      this.isRecording = false;
      this.isPaused = false;

      if (this.isTimed) this.stopTimeout();
      this.stopTimer();
      this.elapsedTime = 0;
    });
  }

  reset() {
    this.recorder.stop(_ => {});
    this.recorder.reset();
    this.isActive = false;
    if (!this.video) return;
    this.video.muted = true;
    this.video.pause();
    this.video.currentTime = 0;
    this.video.removeAttribute("src");
    this.video.srcObject = null;
    this.isRecording = false;
    this.elapsedTime = 0;
    this.stopTimer();
    if (this.isTimed) this.stopTimeout();
  }

  uploadDialog = false;
  isShared = this.defaultShared;
  askForFeedback = false;
  openUpload() {
    this.uploadDialog = true;
  }
  cancelUpload() {
    this.uploadDialog = false;
    this.isShared = false;
    this.reset();
  }
  upload() {
    if (!this.blob) return;

    const maxSize = 400000000;
    // const maxSize = 4000;

    if (this.blob.size > maxSize) {
      this.isTooBig = true;
      return;
    }

    this.$emit("upload", {
      blob: this.blob,
      isShared: this.isShared,
      askForFeedback: this.askForFeedback,
    });
    this.cancelUpload();
  }

  mounted() {
    this.video = document.getElementById("video") as HTMLVideoElement;
  }
}
